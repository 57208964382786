/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useContext } from "react";
import Product from "~/components/Product";
import {
  clientProducts,
  nonContractedProducts,
} from "~/services/productService";
import * as St from "./styles";
import ContractedProduct from "~/components/ContractedProduct";
import Layout from "~/components/Layout";
import MONTHS from "~/assets/mock/monthsPT_BR.json";
import ContainerBox from "~/components/ContainerBox";
import Loader from "~/components/Loader";
import themes from "~/themes";
import { logout } from "~/services/authentication";
import TwoButtonPopup from "~/components/Modals/TwoButtonPopup";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { GlobalContext } from "~/contexts/GlobalState";

export default (props) => {
  const [contractedProducts, setContractedProducts] = useState([]);
  const [nonProductsContracted, setNonContractedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userBalance, setUserBalance] = useState();
  const tokenCard = localStorage.getItem("@NovaEra:tokenCartao");
  const [showPopup, setShowPopup] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [productActive, setProductActive] = useState(false);
  const [statusCliente, setStatusCliente] = useState("");
  const [visibilityBalance, setVisibilityBalance] = useState(
    localStorage.getItem("@NovaEra:balanceVisibility") === "true"
  );

  const { user } = useContext(GlobalContext);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const nonContractedProductsResponse = await nonContractedProducts();
        const filteredProducts = nonContractedProductsResponse.data.listaProdutos.filter(
          produto => produto.tipoProduto !== "EMPRESTIMO"
        );

        setNonContractedProducts(filteredProducts);
        if (
          nonContractedProductsResponse.data.listaProdutos[0]?.tipoProduto ===
          "CARTEIRA_DIGITAL"
        ) {
          if (
            nonContractedProductsResponse.data.listaProdutos[0]?.ativo === true
          ) {
            setProductActive(true);
          }
        }
        if (
          nonContractedProductsResponse.data.listaProdutos[0]?.tipoProduto ===
          "TEUCARD"
        ) {
          if (
            nonContractedProductsResponse.data.listaProdutos[0]?.ativo === true
          ) {
            setProductActive(true);
          }
        }

        // if (
        //   nonContractedProductsResponse.data.listaProdutos[0]?.tipoProduto ===
        //   "EMPRESTIMO"
        // ) {
        //   if (
        //     nonContractedProductsResponse.data.listaProdutos[0]?.ativo === true
        //   ) {
        //     setProductActive(true);
        //   }
        // }
        const responseContractedProducts = await clientProducts();
        setContractedProducts(responseContractedProducts.data.produtosCliente);
        setIsLoading(false);
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (tokenCard !== "undefined") {
      getClientStatus();
    }
  }, [tokenCard]);
  useEffect(() => {
    if (visibilityBalance) {
      handleGetUserBalance();
    }
  }, [visibilityBalance]);

  const PostCardValidation = useApi(
    Endpoints.autorizador.postCartao,
    ApiMethod.POST
  );

  const PostLimitBalance = useApi(
    Endpoints.autorizador.postSaldo,
    ApiMethod.POST
  );

  const formatBestDay = (date = "") => {
    const [day, month] = date.split("/");
    return `${day} de ${MONTHS[Number(month) - 1]}`;
  };

  const cartoesCadastrados = (qnt) => {
    if (qnt < 1 || qnt > 1) {
      return `${qnt} cartões`;
    } else {
      return `${qnt} cartão`;
    }
  };
  const userData = JSON.parse(localStorage.getItem("@NovaEra:infoUsuario"));
  const getLimitsWithDraw = useApi(
    Endpoints.lendings.getLimitAvailable.replace("PARAM_CPF", userData?.cpf),
    ApiMethod.GET
  );

  const handleGetUserBalance = async () => {
    try {
      const response = await PostLimitBalance.callApi({
        tokenCartao: tokenCard,
      });

      if (response.status >= 200 && response.status < 300) {
        await getLimitsWithDraw.callApi();
        setUserBalance(response.data);
      } else {
        setShowErrorPopup(true);
      }
    } catch (error) {
      console.log(error);
      setShowErrorPopup(true);
    }
  };

  const formatExpirationDate = (date = "") => date.split("/")[0];

  const validatingCard = () => {
    logout("/cadastro");
  };

  const handleBalance = (value) => {
    if (value) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(value);
    } else {
      return "0,00";
    }
  };

  const blockCardMessageReturn = () => {
    return (
      <St.BlockCardDescription>
        <p>
          O teu cartão está bloqueado, entre em contato pelo nr. 4003-3203. Ou,
          clique <a>aqui</a> e acesse o portal de negociações!
        </p>
      </St.BlockCardDescription>
    );
  };

  const { addLending } = useContext(GlobalContext);

  const getClientStatus = useCallback(async () => {
    try {
      const response = await PostCardValidation.callApi({
        tokenCartao: tokenCard,
      });
      setStatusCliente(response.data.statusCliente);
      sessionStorage.setItem(
        "@NovaEra:statusCliente",
        response.data.statusCliente
      );
      addLending({
        statusCartao: response.data.statusCliente,
      });
    } catch (err) {}
  }, []);

  const content = contractedProducts?.map(
    (item) =>
      (item.tipoProduto === "EMPRESTIMO" && (
        <ContractedProduct
          key={item.tipoProduto}
          title={item.nome}
          path="/loan-home"
        />
      )) ||
      (item.tipoProduto === "CARTEIRA_DIGITAL" && (
        <ContractedProduct
          key={item.tipoProduto}
          title={item.nome}
          path="/carteira-digital-home"
          descriptions={[
            {
              title: "Cartões cadastrados",
              value: cartoesCadastrados(item.qtdCartoes),
            },
          ]}
        />
      )) ||
      (item.tipoProduto === "TEUCARD" &&
        ((statusCliente === "ATIVO" || statusCliente === "INADIMPLENTE") &&
        tokenCard ? (
          <>
            {PostLimitBalance.loading ? (
              <St.WrapperLoader style={{ width: "40%" }}>
                <Loader />
              </St.WrapperLoader>
            ) : (
              <ContractedProduct
                key={item.tipoProduto}
                title={item.nome}
                statusCliente={sessionStorage.getItem("@NovaEra:statusCliente")}
                path="/cartao-main"
                limiteSaqueDisponivel={handleBalance(
                  getLimitsWithDraw?.data?.limiteSaqueDisponivel
                )}
                saldo={handleBalance(userBalance?.saldo)}
                showBalanceVisibility={(prev) => setVisibilityBalance(!prev)}
                descriptions={[
                  {
                    title: "Melhor data compra",
                    value: formatBestDay(userBalance?.melhorDia),
                  },
                  {
                    title: "Vencimento da fatura",
                    value: `dia ${formatExpirationDate(
                      userBalance?.dataVencimento
                    )}`,
                  },
                ]}
              />
            )}
          </>
        ) : statusCliente === "INATIVO" ? (
          <St.InativeCard onClick={() => setShowPopup(true)}>
            <St.InativeCardHeader>
              <St.InativeCardHeaderTitle>
                {" "}
                Cartão TeuCard{" "}
              </St.InativeCardHeaderTitle>
            </St.InativeCardHeader>
            <St.InativeCardBody>
              <St.InativeCardDescription>
                <p> O teu cartão precisa ser desbloqueado/validado </p>
                {statusCliente !== "ATIVO" && statusCliente !== "COBRANCA" && (
                  <span>Clique aqui</span>
                )}
              </St.InativeCardDescription>
            </St.InativeCardBody>
          </St.InativeCard>
        ) : (
          <St.InativeCard
            onClick={() =>
              statusCliente === "COBRANCA"
                ? window.open("https://negocie.useteucard.com.br/")
                : null
            }
          >
            <St.InativeCardHeader>
              <St.InativeCardHeaderTitle>
                {" "}
                Cartão TeuCard{" "}
              </St.InativeCardHeaderTitle>
            </St.InativeCardHeader>
            <St.InativeCardBody>
              <St.InativeCardDescription>
                <p>
                  {" "}
                  {statusCliente === "COBRANCA"
                    ? blockCardMessageReturn()
                    : "O teu cartão está bloqueado, solicite uma 2ª via"}{" "}
                </p>
              </St.InativeCardDescription>
            </St.InativeCardBody>
          </St.InativeCard>
        )))
  );

  const textModal = (
    <St.TextModal>
      <p>Oba! Você será direcionado para desbloquear/validar o teu cartão. </p>
      <p>Deseja continuar?</p>
    </St.TextModal>
  );

  // Teste Pipline

  return (
    <>
      <Layout showNavBar={false} showBackGroundBar={false}>
        <TwoButtonPopup
          bodyText={textModal}
          leftButtonText="Não"
          rightButtonText="Continuar"
          visibility={showPopup}
          setVisibility={setShowPopup}
          confirm={validatingCard}
          onClickCancel={() => setShowPopup(false)}
          showIcon={false}
        />
        <OneButtonPopup
          bodyText="Erro ao buscar teu saldo!"
          leftButtonText="Não"
          rightButtonText="Continuar"
          visibility={showErrorPopup}
          setVisibility={setShowErrorPopup}
          icon={themes.components.icons.warning}
        />
        <St.Container>
          <ContainerBox>
            <St.TitleStyle>TEUS PRODUTOS</St.TitleStyle>
            <St.ContractedProdutos>
              {!isLoading && content}
              {isLoading && (
                <St.WrapperLoader>
                  <Loader />
                </St.WrapperLoader>
              )}
            </St.ContractedProdutos>
          </ContainerBox>
          <br />
          <br />
          <ContainerBox>
            {productActive === true &&
              !isLoading &&
              nonProductsContracted?.length > 0 && (
                <St.TitleStyle>APROVEITE OUTROS BENEFÍCIOS</St.TitleStyle>
              )}
            <St.AproveitaAdquira
              bannerImage={
                productActive === false &&
                !isLoading &&
                themes.components.images.banner2Web
              }
            >
              {productActive === true &&
                !isLoading &&
                nonProductsContracted?.length > 0 &&
                nonProductsContracted?.map((prod) => (
                  <Product
                    key={prod.id}
                    produto={prod}
                    statusCartao={statusCliente}
                  />
                ))}
              {isLoading && (
                <St.WrapperLoader>
                  <Loader />
                </St.WrapperLoader>
              )}
            </St.AproveitaAdquira>
          </ContainerBox>
        </St.Container>
      </Layout>
    </>
  );
};
