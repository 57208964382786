import React, { useMemo, useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Input from "~/components/Input";
import Button from "~/components/Button";

import { GlobalContext } from "~/contexts/GlobalState";
import themes from "~/themes";
import { useAuth } from "~/hooks/auth";
import { cpfMask } from "~/utils/mask";
import OneButtonPopup from "~/components/Modals/OneButtonPopup";
import { numericMask } from "~/utils/mask";

import * as S from "./styles";
import useApi, { ApiMethod } from "~/hooks/useApi";
import Endpoints from "~/services/endpoints";
import { CustomizedError } from "~/utils/errors";
import LayoutLogin from "~/components/LayoutLogin";

const Login = () => {
  const history = useHistory();
  const [document, setDocument] = useState("");
  const [password, setPassword] = useState("");
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [popUpMessage, setPopUpMessage] = useState("");
  const [confirmationPopupVisibility, setOneButtonPopupVisibility] =
    useState(false);
  const [error, setError] = useState("");

  const { handleChangeAuth } = useAuth();
  const { addLoggedUser, addUser } = useContext(GlobalContext);

  useEffect(() => {
    localStorage.removeItem("@NovaEra:tokenBearer");
    localStorage.removeItem("@NovaEra:token");
  }, []);

  const RequestPostLogin = useApi(Endpoints.usuario.postLogin, ApiMethod.POST);

  const handleLogin = async () => {
    setButtonIsLoading(true);

    try {
      const payload = {
        cpf: document.replace(/\.|-/g, ""),
        senha: password,
        origem: "WEB",
      };

      const response = await RequestPostLogin.callApi(payload);

      switch (response.status) {
        case 201:
          const {
            token,
            novoToken,
            perfis,
            id,
            modeloTemaCartao,
            tokenCartao,
          } = response.data;

          const isUser = perfis.some((el) => el === "USUARIO");
          if (!isUser)
            throw CustomizedError({
              message: "Você não se encontra como usuário atualmente.",
            });

          localStorage.setItem("@NovaEra:token", token);
          localStorage.setItem("@NovaEra:tokenCartao", tokenCartao);
          localStorage.setItem("@NovaEra:tokenBearer", novoToken);
          localStorage.setItem("@NovaEra:idUsuario", id);
          localStorage.setItem("@NovaEra:perfilUsuario", perfis);
          localStorage.setItem(
            "@NovaEra:infoUsuario",
            JSON.stringify(response.data)
          );
          localStorage.setItem("@NovaEra:temaDoCartao", modeloTemaCartao);

          addLoggedUser(response.data);
          addUser(response.data);
          handleChangeAuth(true);

          history.push("/home");

          return response.data;
        default:
          throw CustomizedError({
            status: response.status,
            message: response.data.mensagem || response.status,
          });
      }
    } catch (err) {
      setOneButtonPopupVisibility(true);
      setPopUpMessage(err.message);
    } finally {
      setButtonIsLoading(false);
    }
  };

  const activeButtonLogin = useMemo(
    () => document.length === 14 && password.length === 6,
    [password, document]
  );

  function handleValidForgot(evt) {
    evt.preventDefault();
    if (!document) {
      console.log(document);
      setPopUpMessage(
        "Favor informar o CPF, para continuar com a recuperação de senha."
      );
      setOneButtonPopupVisibility(true);
      setError("Informar o CPF");
    } else {
      setError("");
      history.push("/forgot-password", { cpf: document.replace(/\.|-/g, "") });
    }
  }

  return (
    <LayoutLogin auth={false}>
      <S.Content>
        <S.Main>
          <img src={themes.components.images.logoTeuCard} alt="Logo TeuCard" />
          <p id="title">Entrar na tua conta.</p>
          <Input
            type="text"
            label="CPF"
            maxLength={14}
            value={document}
            onChange={(e) => setDocument(cpfMask(e.target.value))}
            error={error}
          />
          <Input
            icon
            label="Senha"
            maxLength={6}
            value={password}
            onChange={(e) => setPassword(numericMask(e.target.value))}
          />
          {/* <S.HiperlinkPass onClick={handleValidForgot}>
            Esqueci minha senha
          </S.HiperlinkPass> */}
          <S.WrapperButton>
            <Button
              type="submit"
              onClick={handleLogin}
              disabled={!activeButtonLogin || buttonIsLoading}
              isLoading={buttonIsLoading}
            >
              Entrar
            </Button>
          </S.WrapperButton>
        </S.Main>
        <S.ContentVersionNew>
          <S.VersionNew>{process.env.REACT_APP_VERSAO_APP}</S.VersionNew>
        </S.ContentVersionNew>
      </S.Content>
      <S.BackgroundLogin />
      <OneButtonPopup
        defaultButton
        icon={themes.components.icons.cardBlocked}
        text={popUpMessage}
        buttonText="Entendi"
        visibility={confirmationPopupVisibility}
        setVisibility={() => setOneButtonPopupVisibility(false)}
      />
    </LayoutLogin>
  );
};

export default Login;
