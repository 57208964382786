import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "~/contexts/GlobalState";
import * as St from "./styles";

export default (props) => {
  const history = useHistory();
  const { addOnboarding, addLending } = useContext(GlobalContext);
  const knowMoreAboutProducts = (data) => {
    if (data === "TEUCARD") {
      window.open(
        `${process.env.REACT_APP_URL_PROPOSTA}/cadastro-minimo/?98FA1C264E`,
        "_self"
      );
    } else if (data === "CARTEIRA_DIGITAL") {
      sessionStorage.setItem("tipoProdutoSession", data);
      addOnboarding({ idProduto: props.produto.tipoProduto });
      history.push("/onboarding-digital-wallet");
    } 
    // else if (data === "EMPRESTIMO") {
    //   sessionStorage.setItem("tipoProdutoSession", data);
    //   addLending({
    //     idProduto: props.produto.tipoProduto,
    //     statusCartao: props.statusCartao,
    //   });
    //   history.push("/emprestimo/escolher-tipo");
    // }
  };
  return (
    <>
      <St.Container>
        <St.ProductImage imageUrl={props.produto.imagem}>
          <St.ProductFuncoes>
            <St.ProductInformation>
              {props.produto.mensagem}
            </St.ProductInformation>
            <St.ProductButton
              onClick={() => {
                knowMoreAboutProducts(props.produto.tipoProduto);
              }}
            >
              Saiba mais
            </St.ProductButton>
          </St.ProductFuncoes>
        </St.ProductImage>
      </St.Container>
    </>
  );
};
